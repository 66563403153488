<template>
  <div>
    <!-- 批量设置商品排序 -->
    <el-drawer
      style="font-size: 18px"
      title="审核"
      :visible.sync="drawer"
      direction="rtl"
      size="55%"
    >
      <div style="padding: 20px; font-size: 13px">
        <div class="titlxtd">商品基本信息</div>
        <div class="cardes">
          <div><span>商品名称：</span> {{ routfrom.name || "-" }}</div>
          <div><span>生产厂家:</span>{{ routfrom.manufacturer || "-" }}</div>
          <div><span>商品编号:</span>{{ routfrom.id || "-" }}</div>
          <div>
            <span>药品上市许可持有人:</span
            >{{ routfrom.marketingAuthorizationHolder || "-" }}
          </div>
          <div><span>批准文号:</span>{{ routfrom.approvalNumber || "-" }}</div>
          <div><span>产地:</span>{{ routfrom.placeOrigin || "-" }}</div>
          <div>
            <span>规格 单位:</span>{{ routfrom.specification || "-" }}
            <!-- {{ routfrom.unit }} -->
          </div>
          <div>
            <span>经营类别:</span>{{ routfrom.businessscopestring || "-" }}
          </div>
        </div>
        <div class="titlxtd">商品图片</div>
        <UpimgList
          :imgnumber="6"
          :imglist="routfrom.pictureUrlList"
          @Upimgsun="Upimgsun"
          :imageSize="3"
          :nourl="true"
        />
        <div slot="footer" style="margin-top: 120px; text-align: center">
          <el-button type="primary" @click="confirm" :loading="loading"
            >保 存</el-button
          >
          <el-button @click="close()">取 消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const Goods = createNamespacedHelpers("Goods");
import UpimgList from "@/components/Updata/UpimgList.vue";
import {
  platformGoodsdetail, //商品资料库-商品资料详情
  updatePicture, //商品资料库-商品资料更新图片
} from "@/api/apiAll/phpUrl";
export default {
  name: "",
  components: {
    UpimgList,
  },
  data() {
    return {
      drawer: false, //导入弹窗
      loading: false,
      id: "",
      routfrom: {
        pictureUrlList: [],
      },
    };
  },
  created() {},
  methods: {
    ...Goods.mapActions([
      "getPlatformGoodsDetail", //商品资料库-商品资料详情
    ]),
    //初始化
    async init(id) {
      this.id = id;
      Object.assign(this.routfrom, this.$options.data().routfrom);
      let res = await this.getPlatformGoodsDetail({
        id: id,
      });
      await Object.assign(this.routfrom, res.content);
      this.drawer = true;
    },
    //拖拽图片
    Upimgsun(imglist) {
      this.routfrom.pictureUrlList = [...imglist];
    },
    /** 保存 */
    confirm() {
      var data = {
        id: this.id,
        picture_url: this.routfrom.pictureUrlList,
      };
      this.loading = true;
      updatePicture(data)
        .then((res) => {
          this.loading = false;
          if (res.code == "200") {
            this.$message.success("保存成功");
            this.drawer = false;
            this.$emit("getList");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /** 取消 */
    close() {
      this.drawer = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.cardes {
  display: flex;
  flex-wrap: wrap;
  color: #555;
  margin-bottom: 20px;
  .lusdet {
    display: inline-block;
    padding: 4px 7px;
    font-size: 13px;
    text-align: center;
    color: #fff;
    background: #06b7ae;
    border-radius: 5px;
  }
  .business_type {
    font-size: 14px;
    padding: 3px 8px;
    // height: 24px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 5px;
    color: #00bfbf;
    background-color: rgba($color: #00bfbf, $alpha: 0.09);
  }
}
.cardes > div {
  width: calc(50% - 30px);
  margin-right: 30px;
  box-sizing: border-box;
  padding: 10px;
}
.cardes > div > span {
  display: inline-block;
  width: 70px;
  color: #000;
  font-weight: bold;
}
</style>